.text-center {
    text-align: center;
}

.text-muted {
    color: #6c757d !important;
}


@keyframes blinker {
    50% {
        opacity: 0;
    }
}
